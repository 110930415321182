import partnerconfig from "../partnerconfig.json";

const jwtKey = "jwt";
const emailKey = "emailAddress";
const idKey = "partnerId";
const profileUrlKey = "profileUrl";
const signOutUrlKey = "signOutUrl";
const countryKey = "country";

const mobileNumberKey = "mobileNumber";
const emailAddressKey = "emailAddress";

const oneTimeJWTKey = "oneTimeJWT";
const setPwdUrlKey = "setPwdUrl";

const storeAuthInfo = async (authInfo) => {
    try {
        await localStorage.setItem (jwtKey, authInfo.jwt);
        await localStorage.setItem (emailKey, authInfo.emailAddress);
        await localStorage.setItem (idKey, authInfo.partnerId);
        await localStorage.setItem (profileUrlKey, authInfo.profileUrl);
        await localStorage.setItem (signOutUrlKey, authInfo.signOutUrl);
        await localStorage.setItem (mobileNumberKey,authInfo.mobileNumber);
    } catch (error) {
        console.log("AppAuthStorage: storeAuthInfo Error storing the info", error);
    }
}

const getAuthInfo = async () => {
    try {
        const jwt = await localStorage.getItem (jwtKey);
        if ( !jwt ) return null;
        const emailAddress = await localStorage.getItem (emailKey);
        const mobileNumber = await localStorage.getItem (mobileNumberKey);
        const partnerId = await localStorage.getItem (idKey);
        const profileUrl = await localStorage.getItem (profileUrlKey);
        const signOutUrl = await localStorage.getItem (signOutUrlKey);

        // const profileUrl = PartnerServiceConfig.jrmPartnerServiceUrl + PartnerServiceConfig.jrmPartnerServicePath + partnerId;
        // const signoutUrl = profileUrl + "/signout";

        console.log("AppAuthStorage getAuthInfo Value read from store", jwt, emailAddress, partnerId, profileUrl, signOutUrl);

        const authInfo = { "jwt": jwt, "emailAddress": emailAddress, "partnerId": partnerId,
                           "profileUrl": profileUrl, "signOutUrl": signOutUrl };
        
        console.log("AppAuthStorage getAuthInfo authInfo jSON", authInfo);
        return authInfo;
    } catch (error) {
        console.log("AppAuthStorage: getAuthInfo Error retrieving the info", error);
    }

    return null;
}

const removeAuthInfo = async () => {
    try {
        await localStorage.removeItem (jwtKey);
        await localStorage.removeItem (emailKey);
        await localStorage.removeItem (idKey);
        await localStorage.removeItem (profileUrlKey);
        await localStorage.removeItem (signOutUrlKey);
        await localStorage.removeItem (mobileNumberKey);
    } catch (error) {
        console.log("AppAuthStorage: removeAuthInfo Error removing the info ", error);
    }
}

const storeContactInfo = async (contactInfo) => {
    try {
        await localStorage.setItem (mobileNumberKey, contactInfo.mobileNumber);
        await localStorage.setItem (emailAddressKey, contactInfo.emailAddress);
        await localStorage.setItem (countryKey, contactInfo.country);
    } catch (error) {
        console.log("AppAuthStorage: Contact Info - Error storing", error);
    }
}

const getContactInfo = async () => {
    try {
        const emailAddress = await localStorage.getItem (emailAddressKey);
        const mobileNumber = await localStorage.getItem (mobileNumberKey);
        const country = await localStorage.getItem (countryKey);

        console.log("ContactInfo Value read from store", emailAddress, mobileNumber, country);

        const contactInfo = { "emailAddress": emailAddress, "mobileNumber": mobileNumber, "country": country };
        
        console.log("ContactInfo JSON", contactInfo);
        return contactInfo;
    } catch (error) {
        console.log("AppAuthStorage: Contact Info - Error retrieving", error);
    }

    return null;
}

const removeContactInfo = async () => {
    try {
        await localStorage.removeItem (emailAddressKey);
        await localStorage.removeItem (mobileNumberKey);
    } catch (error) {
        console.log("AppAuthStorage: Contact Info - Error removing", error);
    }
}

const storeSetPasswordInfo = async (response) => {
    try {
        await localStorage.setItem (oneTimeJWTKey, response.oneTimeJWT);
        await localStorage.setItem (setPwdUrlKey, response.passwordSetUrl);
    } catch (error) {
        console.log("AppAuthStorage: SetPassword Info - Error storing", error);
    }
}

const getSetPasswordInfo = async () => {
    try {
        const oneTimeJWT = await localStorage.getItem (oneTimeJWTKey);
        const passwordSetUrl = await localStorage.getItem (setPwdUrlKey);

        console.log("SetPasswordInfo Value read from store", oneTimeJWT, passwordSetUrl);

        const setPasswordInfo = { "oneTimeJWT": oneTimeJWT, "passwordResetUrl": passwordSetUrl };
        
        console.log("SetPasswordInfo JSON", setPasswordInfo);
        return setPasswordInfo;
    } catch (error) {
        console.log("AppAuthStorage: SetPassword Info - Error retrieving", error);
    }

    return null;
}

const removeSetPasswordInfo = async () => {
    try {
        await localStorage.removeItem (oneTimeJWTKey);
        await localStorage.removeItem (setPwdUrlKey);
    } catch (error) {
        console.log("AppAuthStorage: SetPassword Info - Error removing", error);
    }
}




export {
    storeAuthInfo,    
    getAuthInfo,
    removeAuthInfo,
    storeContactInfo,
    getContactInfo,
    removeContactInfo,
    storeSetPasswordInfo,
    getSetPasswordInfo,
    removeSetPasswordInfo,
};

