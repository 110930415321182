import { ActionTypes } from "../components/actionTypes";

let initialQuiz = {
    quiz: {
        config: {
            allowBack: true,
            allowReview: false,
            autoMove: true, // if true, it will move to next question automatically when answered.
            duration: 0, // indicates the time in which quiz needs to be completed. 0 means unlimited.
            pageSize: 1,
            requiredAll: true, // indicates if you must answer all the questions before submitting.
            richText: false,
            shuffleQuestions: true,
            shuffleOptions: true,
            showClock: true,
            showPager: true,
            theme: "none",
        },
        questions: [],
    },
    mode: "quiz",
    pager: {
        index: 0,
        size: 1,
        count: 1,
    },
};

export default (state = { ...initialQuiz }, action) => {
    switch (action.type) {
        case ActionTypes.PagerUpdate:
            return {
                ...state,
                pager: action.payload,
                mode: "quiz",
            };
        case ActionTypes.QuizLoad:
            return {
                ...state,
                quiz: action.payload,
            };
        case ActionTypes.QuizSubmit:
            console.log("quiz.js Submitted.");
            return {
                ...state,
                mode: "result",
            };
        case ActionTypes.QuizAnswer:
            console.log("quiz.js reducer Quiz Answer called.");
            return {
                ...state,
                quiz: action.payload,
            };
        default:
            return state;
    }
};
