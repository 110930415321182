import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack, IconButton } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import Iconify from '../../components/Iconify';

import Image from '../../components/Image';
import SocialsButton from '../../components/SocialsButton';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'JRM',
    children: [
      { name: 'About us', href: PATH_PAGE.about },
      { name: 'Contact us', href: PATH_PAGE.contact },
      { name: 'FAQs', href: PATH_PAGE.faqs },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: '#' },
      { name: 'Privacy Policy', href: '#' },
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: 'it@jesusredeems.org', href: '#' },
      { name: 'Tabernacle of God, Nalumavadi, Tuticorin', href: '#' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
    <Container sx={{ pb: 1 }}>
      {/* <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center"
          alignItems="center">
            
          <Grid item xs={2}  md={2}>
          <Typography  component="p"  variant="body1"
          sx={{ 
            fontSize: 15,
            textAlign: { xs: 'center', md: 'center' },
          }}  >
           Download our app on
          </Typography>

          <Stack  direction="row"  justifyContent={{ xs: 'center' }}
            sx={{ mt: 1, mb: { xs: 1, md: 0 } }}
          >
            <IconButton href="https://play.google.com/store/apps/details?id=com.ci.evangel" target="_blank" rel="noreferrer">
                  <Iconify icon={'logos:google-play-icon'} width={20} height={20} />
                </IconButton>
                <IconButton href="https://apps.apple.com/in/app/evangelstream/id1602731863" target="_blank" rel="noreferrer">
                  <Iconify icon={'logos:apple-app-store'} width={20} height={20} />
                </IconButton>
          </Stack>
          
          </Grid>
          
      </Grid> */}
      <Typography  component="p"  variant="body2"
          sx={{
            mb:2,
            fontSize: 15,
            textAlign: { xs: 'center', md: 'center' },
          }}  >
           Jesus Redeems Ministries © 2022 - 2024
        </Typography>
        
      </Container>
    </RootStyle>
  );
}
