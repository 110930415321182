import * as Yup from 'yup';
import { useState, useContext } from 'react';
import * as React from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// routes
import { PATH_AUTH } from '../../../routes/paths';
import { PATH_AFTER_LOGIN } from '../../../config';

import Iconify from '../../../components/Iconify';

import { AppAuthContext } from '../../../auth/AppAuthContext';
import { storeAuthInfo } from '../../../auth/AppAuthStorage';
import { loginPartner } from '../../../services/JRMPartnerAuthService';

// ----------------------------------------------------------------------

export default function LoginFormik() {
  const navigate = useNavigate();
  const authContext = useContext (AppAuthContext);
  const saveAuthInfo = async (authInfo) => {
    console.log ("Storing Auth Info ", authInfo);
    storeAuthInfo(authInfo);
}

  const [showPassword, setShowPassword] = useState(false);
  const LoginSchema = Yup.object().shape({
    emailAddress: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required').min(6,"min length should be 6"),
  });

  const formik = useFormik({
    initialValues: {
      emailAddress: '',
      password: '',
      remember: false,
      client: 'vbs'
    },
      validationSchema: LoginSchema,
      onSubmit : async (values) => {
          const result = await loginPartner(values);
          //  If there is no result then it is an error
          if ( !result ) {
            toast.error ("Error: Unable to communicate");
            console.log("Error: Unable to communicate")
            return;
        }
        if (!result.ok) {
          console.log("Login FAILED status=", result.status)
          if ( result.status === 401) {
              toast.error ("Invalid email and/or password");
              console.log("Login FAILED status=", result.status)
          } else {
              toast.error ("Could not able to login ");
              console.log("Login FAILED status=", result.status)
          }
          return;
      }  
        console.log("Login successful", result.data);
        toast.success("Login successful!");
        //  Store the info received in Login in LocalStorage
        authContext.setUser(result.data);
        saveAuthInfo(result.data);
        navigate(PATH_AFTER_LOGIN, { replace: true });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
       
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email"
            {...getFieldProps('emailAddress')}
            error={Boolean(touched.emailAddress && errors.emailAddress)}
            helperText={touched.emailAddress && errors.emailAddress}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
            <IconButton onClick={handleShowPassword} edge="end" >
            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
          </IconButton>

                 
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />
          <div>
          <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.eForgotPassword} sx={{ mt: 1 }}>
      Forgot password?
      </Link>
      
    </div>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
