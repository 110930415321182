
import apiClient from "./Client";
import config from '../partnerconfig.json';

const registerPartnerMobile = (partnerDetails) => {

    console.log ("registerPartnerMobile", partnerDetails);
    return apiClient.post (config.jrmPartnerRegisterMobileUrl,
                           partnerDetails);
};

const verifyMobileOTP = (otp) => {

    console.log ("verifyMobileOTP", otp);
    return apiClient.post (config.jrmPartnerVerifyMobileUrl, otp);
}
const resendMobileOTP = (otp) => {

    console.log ("resendMobileOTP", otp);
    return apiClient.post (config.jrmResendOTPUrl, otp);
};

const verifyEmailOTP = (otp) => {

    console.log ("verifyEmailOTP", otp);
    return apiClient.post (config.jrmPartnerVerifyEmailUrl, otp);
}
const resendEmailOTP = (otp) => {

    console.log ("resendEmailOTP", otp);
    return apiClient.post (config.jrmResendOTPUrl, otp);
};


const setPassword = (setPasswordInfo, passwordDetails) => {

    console.log ("setPassword setPasswordINfo=", setPasswordInfo);
    console.log ("setPassword request=", passwordDetails);

    const config = {
        headers:{
            "Authorization": `Bearer ${setPasswordInfo.oneTimeJWT}`,
            
        }
    };

    const request = { "password": passwordDetails.password};
    console.log ("new password:", request);
    return apiClient.post (setPasswordInfo.passwordResetUrl, request, config);
};

const loginPartner = (credentials) => {

    
    return apiClient.post (config.jrmPartnerLoginUrl,
                           credentials);
};

const logoutPartner = (authInfo) => {

    console.log ("LogoutPartner authInfo=", authInfo);

    const config = {
        headers:{
            "Authorization": `Bearer ${authInfo.jwt}`
        }
    };

    //  Empty request on purpose
    const request = {};
    return apiClient.post (authInfo.signOutUrl, request, config);
}

const updatePartnerContactInfo = (partnerUrl, jwt, partnerInfo) => {

    const partnerContactInfoUrl = `${partnerUrl}/contactinfo`;

    console.log ("updatePartnerContactInfo", partnerUrl, partnerContactInfoUrl, partnerInfo);

    const config = {
        headers:{
            "Authorization": `Bearer ${jwt}`
        }
    };

    return apiClient.post (partnerContactInfoUrl, partnerInfo, config);
};

const updatePartnerSpouseInfo = (partnerUrl, jwt, partnerInfo) => {

    const partnerSpouseInfoUrl = `${partnerUrl}/spouseinfo`;

    console.log ("updatePartnerSpouseInfo", partnerUrl, partnerSpouseInfoUrl, partnerInfo);

    const config = {
        headers:{
            "Authorization": `Bearer ${jwt}`
        }
    };

    return apiClient.post (partnerSpouseInfoUrl, partnerInfo, config);
};

const initiatePasswordReset = (data) => {

    console.log ("initiatePasswordReset", data);
   // const request = { "mobile": mobile };
    return apiClient.post (config.jrmInitPasswordResetUrl, data);
}
const getPartnerContactInfo = (partnerContactInfoUrl, jwt) => {
    console.log ("JRMAuthService getPartnerContactInfo", partnerContactInfoUrl, jwt);

    const config = {
        headers:{
            "Authorization":`Bearer ${jwt}`
        }
    };

    return apiClient.get(partnerContactInfoUrl, "", config);
};

const getPartnerSpouseInfo = (partnerSpouseInfoUrl, jwt) => {
    console.log ("JRMAuthService getPartnerSpouseInfo", partnerSpouseInfoUrl, jwt);

    const config = {
        headers:{
            "Authorization": `Bearer ${jwt}`
        }
    };

    return apiClient.get(partnerSpouseInfoUrl, "", config);
};

const updatePartnerChildrenInfo = (partnerUrl, jwt, partnerChildrenInfo) => {

    const partnerChidrenInfoUrl = `${partnerUrl}/childreninfo`;

    console.log ("updatePartnerChildrenInfo", partnerUrl, partnerChidrenInfoUrl, partnerChildrenInfo);

    const config = {
        headers:{
            "Authorization": `Bearer ${jwt}`
        }
    };

    return apiClient.post (partnerChidrenInfoUrl, partnerChildrenInfo, config);
};

const getPartnerChildrenInfo = (partnerChildrenInfoUrl, jwt) => {
    console.log ("JRMAuthService getPartnerChildrenInfo", partnerChildrenInfoUrl, jwt);

    const config = {
        headers:{
            "Authorization": `Bearer ${jwt}`
        }
    };

    return apiClient.get(partnerChildrenInfoUrl, "", config);
};




export {
    registerPartnerMobile,
    loginPartner,
    logoutPartner,
    verifyMobileOTP,
    initiatePasswordReset,
    updatePartnerContactInfo,
    updatePartnerSpouseInfo,
    setPassword,
    getPartnerSpouseInfo,
    resendMobileOTP,
    verifyEmailOTP,
    resendEmailOTP,
    updatePartnerChildrenInfo,
    getPartnerChildrenInfo,
    getPartnerContactInfo

};